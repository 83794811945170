import { Adresses, AttCommon } from '../../models'

export const convertInterface = (data: Adresses) => {
  let attComonData: AttCommon = {}
  attComonData.id = data.id
  attComonData.addresse = data.addresse
  attComonData.code_postal = data.code_postal
  attComonData.ville = data.ville
  attComonData.iso = data.iso
  attComonData.pays = data.pays
  attComonData.complement = data.complement
  attComonData.gln = data.gln
  return attComonData
}
